<template>
  <div class="content-body">
    <div class="academic-container">
        <div class="row mg-0">
        <div class="col-sm-5">
            <div class="content-header pd-l-0">
            <div>
                <h4 class="content-title content-title-xs">Manage Items</h4>
            </div>
            </div>
            <!-- content-header -->
        </div>
      </div>
        <!-- row -->
        <div class="row my-2">
            <div class="filter-input col-md-9 d-flex mr-0 pr-0">
            <select v-model="filter.size">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
            </select>
            </div>
            <div class="col-md-3 ml-0 pl-0">
            <input
                type="text"
                v-model="filter.key"
                class="float-right px-1"
                placeholder="Search..."
            />
            </div>
        </div>
        <div class="row py-0">
        <div class="col-md-12 year-transfer-table-body">
            <table class="table table1 table-hover" id="tableData">
            <thead>
                <tr>
                <th class="wd-5p table-start-item">S.N</th>
                <th class="wd-25p">Item Name</th>
                <th class="wd-10p">Group</th>
                <th class="wd-10p">Unit Type</th>
                <th class="wd-10p">VAT Applicable</th>
                <th class="wd-10p text-right table-end-item">Action</th>
                </tr>
            </thead>
            <tbody v-for="(item, index) in dataSets" :key="index">
                <tr v-if="!removeList.includes(item.id)">
                <th scope="row" class="table-start-item">{{ ++index }}</th>
                <td>{{ item.name }}</td>
                <td>{{ item.item_group.title }}</td>
                <td>{{ item.unit_type.title }}</td>
                <td>{{ item.vat_applicable==1?'Abblicabe':'Not Abblicabe' }}</td>
                <td class="text-right table-end-item">
                    <a href="javascript:;" @click="drop(item.id)">
                    <i class="fa fa-trash"></i>
                    </a>
                </td>
                </tr>
            </tbody>
            </table>
            <Pagination />
        </div>
        <div class="col-md-12 text-right mt-3">
            <button type="submit" class="btn btn-sm btn-primary" @click="$router.push(`/academic-year-transfer/employee`)">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {Search} from "../../../../../../../mixins/search";
import Pagination from "../../../../../components/pagination/pagination";

export default {
  mixins: [Search],
  components: {
    Pagination,
  },
  methods: {
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    drop(id) {
      this.$store.commit("setDataId", id);
      this.$store.commit("dropRecord", `api/item/${id}`);
    },
    getData() {
      this.$store.commit("getData", `api/items/size/${this.filter.size}`);
    },
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
  },
  mounted() {
    this.getData();
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>